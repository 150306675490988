import React from "react";
import { HiMenuAlt4, HiX } from "react-icons/hi";
import { motion } from "framer-motion";
import { useState } from "react";

import "./Navbar.scss";
import { images } from "../../constants";

const Navbar = () => {
  const [toggle, setToggle] = useState(false);
  return (
    <nav className="app__navbar">
      <div className="app__navbar-logo">
        <img src={images.logo_horizontal} alt="logo" />
      </div>
      <ul className="app__navbar-links">
        <li className="app__flex p-text" key="link-home">
          <div />
          <a href="#home">Inicio</a>
        </li>
        <li className="app__flex p-text" key="link-about">
          <div />
          <a href="#about">Servicios</a>
        </li>
        {/* <li className="app__flex p-text" key="link-work">
          <div />
          <a href="#work">Proyectos</a>
        </li> */}
        <li className="app__flex p-text" key="link-skills">
          <div />
          <a href="#skills">Habilidades</a>
        </li>
      {/*   <li className="app__flex p-text" key="link-testimonial">
          <div />
          <a href="#testimonial">Referencias</a>
        </li> */}
        <li className="app__flex p-text" key="link-contact">
          <div />
          <a href="#contact">Contacto</a>
        </li>

        {/* {["Inicio", "Información", "Trabajos", "Habilidades", "Contacto"].map(
          (item) => (
            <li className="app__flex p-text" key={`link-${item}`}>
              <div />
              <a href={`#${item}`}>{item}</a>
            </li>
          )
        )} */}
      </ul>
      <div className="app__navbar-menu">
        <HiMenuAlt4 onClick={() => setToggle(true)} />
        {toggle && (
          <motion.div
            whileInView={{ x: [200, 0] }}
            transition={{ duration: 0.85, ease: "easeInOut" }}
          >
            <HiX onClick={() => setToggle(false)} />
            <ul>
              <li key="home">
                <a href="#home">Inicio</a>
              </li>
              <li key="about">
                <a href="#about" onClick={() => setToggle(false)}>
                  Servicios
                </a>
              </li>
              {/* <li key="work">
                <a href="#work" onClick={() => setToggle(false)}>
                  Proyectos
                </a>
              </li> */}
              <li key="skills">
                <a href="#skills" onClick={() => setToggle(false)}>
                  Habilidades
                </a>
              </li>
           {/*    <li key="testimonial">
                <a href="#testimonial" onClick={() => setToggle(false)}>
                  Referencias
                </a>
              </li> */}
              <li key="contact">
                <a href="#contact" onClick={() => setToggle(false)}>
                  Contacto
                </a>
              </li>
              {/*   {[
                "Inicio",
                "Información",
                "Trabajos",
                "Habilidades",
                "Contacto",
              ].map((item) => (
                <li key={`${item}`}>
                  <a href={`#${item}`} onClick={() => setToggle(false)}>
                    {item}
                  </a>
                </li>
              ))} */}
            </ul>
          </motion.div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
