import email from '../assets/email.png';
import mobile from '../assets/mobile.png';

import react from '../assets/react.png';


import profile from '../assets/profile.png';
import circle from '../assets/circle.svg';
import logo from '../assets/logo.png';

import laravel from '../assets/laravel.png';
import php from '../assets/php.png';
import logo_horizontal from '../assets/logo_horizontal_sm.png';
import profileVG from '../assets/profileVG.svg';


export default {
  email,
  mobile,
  react,
  profile,
  circle,
  logo,
  laravel,
  php,
  logo_horizontal,
  profileVG
};